<template>
  <div class="grid-container">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-10">
        <h1 class="pb-5">Admin</h1>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-2">
        <router-link
          to="/admin/users">
          <button class="button expanded">
            System Users
          </button>
        </router-link>
      </div>
      <div class="cell small-12 medium-2">
        <router-link
          to="/admin/job-titles">
          <button class="button expanded">
            Job Titles
          </button>
        </router-link>
      </div>
      <div class="cell small-12 medium-2">
        <router-link
          to="/admin/cost-domains">
          <button class="button expanded">
            Cost Domains
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'ClientsIndex',
  data() {
    return {
      clients: [],
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  methods: {
    fetchClients() {
      axios.get(`/clients/fetchAll.json?token=${this.token}`)
        .then((response) => {
          this.clients = response.data.clients;
        })
        .catch(() => {
          this.failed = true;
        });
    },
  },
  mounted() {
    setTimeout(() => {
      this.fetchClients();
    }, 100);
  },
};
</script>
